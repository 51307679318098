.contact {
  padding: 3rem 1rem;
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
}
.contact a {
  text-decoration: none;
  color: #317873;
}

.contact a:hover {
  text-decoration: underline;
}

/* xs */
@media (min-width: 475px) {
}

/* sm */
@media (min-width: 640px) {
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 1280px) {
}

/* xl */
@media (min-width: 1536px) {
}

.contact h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-primary);
}

.contact p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--text-secondary);
}
