.menu {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    padding: 0;
  }
}

.menu li {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 1rem;
  text-align: center;
  a,
  button {
    text-decoration: none;
    color: inherit;
  }
  a:hover {
    text-decoration: underline;
  }
}
