.projectsContainer {
  display: flex;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
  padding: 3rem 0;
}

.projects {
  max-width: 90%;
  margin: 0 auto;
}

.project {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
  justify-content: center;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}

.project img {
  width: 100%;
  height: auto;
  min-height: 250px;
  max-height: 350px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  object-fit: cover;
  transform-origin: center center;
}

.project img:hover {
  transform: translateY(-4px);
}

.projectDescription {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0;
  margin-top: 1rem;
  h3 {
    margin: 0;
  }
}

.heading {
  text-align: center;
  margin: 0 0 2rem 0;
}

.viewButton {
  display: flex;
  align-items: center;
  align-self: center;
  padding: 10px;
  background-color: rgb(20, 20, 20);
  text-decoration: none;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s;
  justify-content: center;
  width: 5rem;
}

.viewButton:hover {
  background-color: #317873;
  color: white;
}

.featuredProject {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.featuredProjectHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.featuredProjectImages {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  img:nth-child(1) {
    width: 50%;
  }
  img:nth-child(2) {
    width: 50%;
  }
  img:nth-child(3) {
    width: 50%;
  }
  img:nth-child(4) {
    width: 50%;
  }
  img:nth-child(5) {
    width: 50%;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  button {
    background-color: rgb(20, 20, 20);
    color: white;
    cursor: pointer;
    width: 5rem;
    height: 2rem;
    border-radius: 5px;
    border: none;
  }
  button:hover {
    background-color: #317873;
    color: white;
  }
}

.techStack {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.techStack > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* xs */
@media (min-width: 475px) {
}

/* sm */
@media (min-width: 640px) {
}

/* md */
@media (min-width: 768px) {
  .project {
    flex-direction: row;
    gap: 2rem;
  }

  .project > div {
    flex: 1;
    width: 50%;
    max-width: 50%;
  }

  .project:nth-child(odd) {
    flex-direction: row-reverse;
  }

  .projectDescription {
    margin-top: 0;
  }
}

/* lg */
@media (min-width: 1280px) {
}

/* xl */
@media (min-width: 1536px) {
}

.featured-project-section {
  max-width: 90%;
  margin: 0 auto;
  padding: 3rem 0;
}

.section-header {
  margin-bottom: 2rem;
  text-align: left;
  padding-left: 1rem;
}

.section-subtitle {
  color: var(--primary-color);
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
  display: block;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-primary);
  margin: 0.5rem 0;
}

.featured-project-content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 0 1rem;
  color: var(--text-primary);
}

.featured-project-info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 800px; /* Optional: limits the text width for better readability */
}

.project-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--text-secondary);
}

.tech-stack h3 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: var(--text-primary);
}

.tech-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.tech-tag {
  background-color: var(--bg-secondary);
  color: var(--primary-color);
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.project-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.btn-primary,
.btn-secondary {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
}

.btn-primary {
  background-color: var(--primary-color, #317873);
  color: white;
}

.btn-primary:hover {
  background-color: var(--primary-color-dark, #245c58);
  transform: translateY(-2px);
}

.btn-secondary {
  background-color: transparent;
  color: var(--text-primary, #333);
  border: 2px solid var(--primary-color, #317873);
}

/* Add this to ensure white text in dark mode */
.dark .btn-secondary {
  color: white;
}

.btn-secondary:hover {
  background-color: var(--primary-color, #317873);
  color: white;
  transform: translateY(-2px);
}

.btn-primary i,
.btn-secondary i {
  font-size: 0.9rem;
}

.featured-project-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 2rem;
  padding: 8px;
  width: 100%;
  position: relative;
  z-index: 1;
}

.featured-image {
  width: 100%;
  height: auto; /* Change from fixed height to auto */
  min-height: 300px; /* Minimum height to maintain aspect */
  max-height: 430px; /* Maximum height as before */
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  object-fit: cover;
  transform-origin: center center;
}

.featured-image:hover {
  transform: translateY(-4px);
}

/* Update responsive sizes */
@media (max-width: 1024px) {
  .featured-image {
    min-height: 250px;
  }
}

@media (max-width: 768px) {
  .featured-project-gallery {
    grid-template-columns: 1fr; /* Stack images on smaller screens */
    gap: 1rem;
  }

  .project img {
    min-height: 200px;
    max-height: 300px;
  }
}

@media (max-width: 480px) {
  .project img {
    min-height: 180px;
    max-height: 250px;
  }
}

.project h3 {
  /* Project titles */
  font-size: 2rem;
  font-weight: 700;
  color: var(--text-primary);
}

.project-description,
.projectDescription {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--text-secondary);
}

.tech-tag {
  font-size: 0.9rem;
  font-weight: 500;
}
