* {
  line-height: calc(1em * 0.5rem);
}

body.light {
  background-color: #f1f5f9;
  color: #000000;
}

body.dark {
  background-color: #191d2b;
  color: #ffffff;
}

img,
svg {
  display: block;
  max-width: 100%;
}

button {
  display: inline-block;
  background: none;
  cursor: pointer;
  border: none;
  padding: 0;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.sectionsContainer {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

/* xs */
@media (min-width: 385px) {
  .container {
    max-width: 375px;
  }
}

/* sm */
@media (min-width: 435px) {
  .container {
    max-width: 425px;
  }
}

/* md */
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

/* lg */
@media (min-width: 1034px) {
  .container {
    max-width: 1024px;
  }
  .sectionsContainer {
    gap: 5rem;
  }
}

/* xl */
@media (min-width: 1450px) {
  .container {
    max-width: 1440px;
  }
}

/* 2xl */
@media (min-width: 2570px) {
  .container {
    max-width: 2560px;
  }
}
