.footerContainer {
  background-color: black;
  height: 5rem;
  display: flex;
  width: 100%;
}

.footer {
  height: 100%;
  display: flex;
}

.footer p {
  margin: auto;
  align-self: center;
  color: white;
}

/* xs */
@media (min-width: 475px) {
}

/* sm */
@media (min-width: 640px) {
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 1280px) {
}

/* xl */
@media (min-width: 1536px) {
}
