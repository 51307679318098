.header {
  width: 100%;
  height: 3rem;
  background-color: rgb(20, 20, 20);
  display: flex;
  position: fixed; /* Make it stick/fixed */
  top: 0; /* Stay on top */
  transition: top 0.3s;
  z-index: 1000; /* Add a high z-index to ensure header stays on top */
}

.navItems {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.navItem {
  display: none;
}

.themeButton {
  display: block;
  color: white;
  width: 20px;
  height: 20px;
}

.hamburger {
  display: block;
  .hamburgerButton {
    display: inline-block;
    color: white;
    width: 20px;
    height: 20px;
  }
}

.shadow {
  box-shadow: 0 4px 4px black;
}

.navLink {
  font-size: 1rem;
  font-weight: 500;
}

/* xs */
@media (min-width: 475px) {
}

/* sm */
@media (min-width: 640px) {
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 1034px) {
  .themeButton {
    display: block;
    color: white;
    height: 20px;
    width: 20px;
  }
  .navItems {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    .navItem {
      display: inline-block;
      .navLink {
        text-decoration: none;
        color: white;
        padding: 5px;
        border-radius: 5px;
        transition: background-color 0.3s;
      }
      .navLink:hover {
        background-color: #317873;
        color: white;
      }
    }
  }
  .verticalLine {
    border-left: 1px solid white;
    height: 1rem;
  }
  .hamburger {
    display: none;
  }
}

/* xl */
@media (min-width: 1536px) {
}
