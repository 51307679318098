.about {
  display: flex;
  flex-direction: column;
  padding: 3rem 1rem;
  max-width: 90%;
  margin: 3rem auto;
}

.about > div {
  flex: 1; /* Make each section take up equal width */
  padding: 1rem;
}

.technologies {
  display: flex;
  gap: 5rem;
  ul {
    padding-left: 0;
  }
  li {
    display: flex;
    color: none;
  }
}
.technologies li::before {
  content: "👍"; /* Replace with your desired emoji */
  margin-right: 1rem;
}

.aboutImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 25rem;
    height: 20rem;
    border-radius: 5px;
  }
}

/* xs */
@media (min-width: 475px) {
}

/* sm */
@media (min-width: 640px) {
}

/* md */
@media (min-width: 768px) {
  .technologies {
    gap: 10rem;
    ul {
      padding-left: 1rem;
    }
  }
}

/* lg */
@media (min-width: 1280px) {
  .about {
    flex-direction: row;
    img {
      width: 90%;
      height: 36rem;
    }
  }
}

/* xl */
@media (min-width: 1536px) {
}

.about h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-primary);
}

.about p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--text-secondary);
}
